"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandingService = exports.JoinLauncherCallerName = exports.ClientCallerHeader = void 0;
const TelemetryEvents_1 = require("../models/telemetry/TelemetryEvents");
const Logger_1 = require("./Logger");
const Utils_1 = require("../helpers/Utils");
const ConfigurationService_1 = require("./ConfigurationService");
const PromisesHelper_1 = require("../helpers/PromisesHelper");
const JoinInfo_1 = require("../models/JoinInfo");
exports.ClientCallerHeader = "x-ms-client-caller";
exports.JoinLauncherCallerName = "JoinLauncher";
class BrandingService {
    constructor(document, requestTimeout, brandingWithImages, ignoreBrandingTimeout) {
        this.document = document;
        this.requestTimeout = requestTimeout;
        this.brandingWithImages = brandingWithImages;
        this.ignoreBrandingTimeout = ignoreBrandingTimeout;
        this.brandingStarted = false;
        this.brandingAppliedDeferred = PromisesHelper_1.default.createDefered();
        this.isBrandingApplied = () => this.brandingAppliedDeferred.promise;
        this.beforeUnloadHandler = () => {
            var _a, _b, _c;
            // Dont mark as incomplete if branding is already completed, failed or timeout has expired or hasn't started yet
            if (((_a = this.brandingEventProps) === null || _a === void 0 ? void 0 : _a.status) === "incomplete" ||
                ((_b = this.brandingEventProps) === null || _b === void 0 ? void 0 : _b.status) === "failure" ||
                ((_c = this.brandingEventProps) === null || _c === void 0 ? void 0 : _c.status) === "success" ||
                !this.brandingStarted) {
                return;
            }
            this.updateBrandingSteps("BrandingFetchIncomplete");
            this.brandingEventProps.status = "incomplete";
            this.brandingEventProps.error = "UserClosedWindow";
            this.brandingEventProps.message = "User closed the window before branding completed";
            this.brandingEventProps.time = this.brandingSteps.BrandingFetchIncomplete;
            this.brandingEventProps.brandingSteps = JSON.stringify(this.brandingSteps);
            this.brandingEventProps.brandingStepsWithTimestamp = JSON.stringify(this.brandingStepsWithTimestamp);
            const brandingEvent = new TelemetryEvents_1.BrandingEvent(this.brandingEventProps);
            Logger_1.default.log(brandingEvent);
        };
        this.fastPlaceholder = document.createElement("div");
        document.body.appendChild(this.fastPlaceholder);
        window.addEventListener("beforeunload", this.beforeUnloadHandler);
    }
    cleanup() {
        window.removeEventListener("beforeunload", this.beforeUnloadHandler);
    }
    startBranding(brandingServiceRequestParameters) {
        let isTimeoutExpired = false;
        if (this.brandingStarted) {
            this.applyUnbrandedTheme();
            this.brandingAppliedDeferred.resolve(false);
            return;
        }
        this.setupMeetingBrandingServiceRequest(brandingServiceRequestParameters);
        const racedPromises = !this.ignoreBrandingTimeout
            ? [
                new Promise((resolve, _reject) => {
                    this.timeoutId = window.setTimeout(() => {
                        this.brandingEventProps.status = "incomplete";
                        this.updateBrandingSteps("BrandingFetchTimedOut");
                        this.brandingEventProps.message = "branding fetch timed out";
                        isTimeoutExpired = true;
                        resolve({});
                    }, this.requestTimeout);
                }),
            ]
            : [];
        this.importFastElementLibrary()
            .then(fastElementLib => {
            this.brandingDelta = Date.now();
            this.updateBrandingSteps("BrandingLibImported");
            this.brandingStarted = true;
            this.getBrandedColors = fastElementLib.default;
            return this.fetchBrandingThemes().then(brandingTheme => {
                this.brandingContent = this.getBrandingThemeContent(brandingTheme);
                this.updateBrandingSteps("BrandingThemeContent");
                racedPromises.push(this.fetchImages(brandingTheme));
                return Promise.race(racedPromises).then(localImageBlobURIs => {
                    if (isTimeoutExpired) {
                        this.applyUnbrandedTheme();
                    }
                    else {
                        clearTimeout(this.timeoutId);
                        this.updateBrandingSteps("BrandingCompleted");
                        this.brandingEventProps.status = "success";
                        this.applyBrandingTheme(brandingTheme.brandAccentColor, localImageBlobURIs);
                        this.brandingAppliedDeferred.resolve(true);
                    }
                });
            });
        })
            .catch((error) => {
            this.brandingEventProps.message = Utils_1.Utils.scrubEuii(error);
            // Show unbranded theme if branding fails at any step
            this.applyUnbrandedTheme();
            this.brandingAppliedDeferred.resolve(false);
        })
            .finally(() => {
            this.brandingEventProps.brandingSteps = JSON.stringify(this.brandingSteps);
            this.brandingEventProps.brandingStepsWithTimestamp = JSON.stringify(this.brandingStepsWithTimestamp);
            this.brandingEventProps.brandingTheme = JSON.stringify(this.brandingContent);
            const brandingEvent = new TelemetryEvents_1.BrandingEvent(this.brandingEventProps);
            Logger_1.default.log(brandingEvent);
        });
    }
    fetchImages(theme) {
        if ((this.brandingContent &&
            !this.brandingContent.hasBackground &&
            !this.brandingContent.hasLogo) ||
            !this.brandingWithImages) {
            return Promise.resolve({});
        }
        const brandingJoinLauncherImages = [
            {
                id: "logoImagePreAuthUri",
                url: `${theme.logoImagePreAuthUri}`,
            },
            {
                id: "backgroundImagePreAuthUri",
                url: theme.backgroundImagePreAuthUri,
            },
        ];
        const getPreAuthTokenLength = (urlString) => {
            var _a, _b;
            try {
                const url = new URL(urlString);
                return (_b = (_a = url.searchParams.get("amsauth")) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
            }
            catch (_) {
                return 0;
            }
        };
        this.updateBrandingSteps("BrandingImagesFetchStarted");
        return Utils_1.Utils.fetchImages(brandingJoinLauncherImages)
            .then(imageURIs => {
            this.updateBrandingSteps("BrandingImagesFetched");
            return imageURIs;
        })
            .catch(error => {
            var _a, _b, _c, _d;
            const preAuthTokenLengths = {
                logoDarkImagePreAuthUri: getPreAuthTokenLength((_a = theme.logoImageDarkPreAuthUri) !== null && _a !== void 0 ? _a : ""),
                logoLightImagePreAuthUri: getPreAuthTokenLength((_b = theme.logoImageLightPreAuthUri) !== null && _b !== void 0 ? _b : ""),
                backgroundDarkImagePreAuthUri: getPreAuthTokenLength((_c = theme.backgroundImageDarkPreAuthUri) !== null && _c !== void 0 ? _c : ""),
                backgroundLightImagePreAuthUri: getPreAuthTokenLength((_d = theme.backgroundImageLightPreAuthUri) !== null && _d !== void 0 ? _d : ""),
            };
            this.brandingEventProps.message = `brandingImageFetchError. Preauth token length ${JSON.stringify(preAuthTokenLengths)}. Error: ${error}`;
            this.brandingEventProps.status = "failure";
            this.brandingEventProps.error = `brandingImageFetchError`;
            this.updateBrandingSteps("BrandingImagesFetchFailed");
            throw error;
        });
    }
    // replaces the imgpsh_fullsize? with imgo? to get the image in the correct size based on
    // https://eng.ms/docs/experiences-devices/m365-core/ic3/messaging/async-media-distribution/views
    changeImageSizePropToIMGO(fullsizeImage) {
        return fullsizeImage.replace("imgpsh_fullsize?", "imgo?");
    }
    importFastElementLibrary() {
        return (
        // eslint-disable-next-line import/dynamic-import-chunkname
        Promise.resolve().then(() => require("../branding")).catch(e => {
            this.brandingEventProps.status = "failure";
            this.brandingEventProps.error = "brandingLibImportError";
            return Promise.reject(e);
        }));
    }
    fetchBrandingThemes() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c, _d;
                this.updateBrandingSteps("BrandingFetchStarted");
                let fetchResponse;
                let fetchResponseJSON;
                try {
                    fetchResponse = yield window.fetch(this.brandingServiceUrl, {
                        mode: "cors",
                        credentials: "omit",
                        headers: {
                            [exports.ClientCallerHeader]: exports.JoinLauncherCallerName,
                        },
                    });
                    this.brandingEventProps.xServerRequestId =
                        (_a = fetchResponse.headers.get("x-serverrequestid")) !== null && _a !== void 0 ? _a : undefined;
                    this.brandingEventProps.xMsEdgeRef = (_b = fetchResponse.headers.get("X-MSEdge-Ref")) !== null && _b !== void 0 ? _b : undefined;
                    fetchResponseJSON = yield fetchResponse.json();
                    if (!fetchResponse.ok) {
                        clearTimeout(this.timeoutId);
                        this.brandingEventProps.status = "failure";
                        this.brandingEventProps.error = "failedToFetchBranding";
                        this.updateBrandingSteps("BrandingFetchFailed");
                        reject(`Failed to fetch branding theme. Server response code: ${(_c = fetchResponse === null || fetchResponse === void 0 ? void 0 : fetchResponse.status) !== null && _c !== void 0 ? _c : ""}. Response: ${JSON.stringify(fetchResponse.body)}`);
                        return;
                    }
                    const brandingTheme = this.getBrandingTheme(fetchResponseJSON);
                    if (!brandingTheme) {
                        clearTimeout(this.timeoutId);
                        this.brandingEventProps.error = "brandingThemeMissingError";
                        this.brandingEventProps.status = "success";
                        this.updateBrandingSteps("BrandingThemeNotFound");
                        reject("brandingThemeMissingError");
                    }
                    else {
                        this.updateBrandingSteps("BrandingFetchFinished");
                        resolve(brandingTheme);
                    }
                }
                catch (error) {
                    if (error instanceof SyntaxError) {
                        // Eror while parsing JSON response
                        this.brandingEventProps.error = "brandingResponseParseError";
                    }
                    else {
                        this.brandingEventProps.error = "genericErrorInBrandingFetch";
                    }
                    this.updateBrandingSteps("BrandingFetchFailed");
                    this.brandingEventProps.status = "failure";
                    clearTimeout(this.timeoutId);
                    reject(error.message);
                }
                finally {
                    this.brandingEventProps.statusCode = (_d = fetchResponse === null || fetchResponse === void 0 ? void 0 : fetchResponse.status) === null || _d === void 0 ? void 0 : _d.toString();
                    this.brandingEventProps.time = this.brandingSteps.BrandingFetchFinished;
                }
            }));
        });
    }
    setupMeetingBrandingServiceRequest(requestParamInfo) {
        this.brandingEventProps = { joinType: requestParamInfo.kind };
        const absoluteUrls = ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls;
        switch (requestParamInfo.kind) {
            case JoinInfo_1.JoinType.MeetupWithCode:
                const { meetingId, passcode, getAppliedMeetingBrandingTheme } = requestParamInfo;
                this.brandingEventProps.code = meetingId;
                this.brandingServiceUrl = `${absoluteUrls.brandingWithMeetingidService}?meetingId=${meetingId}&passcode=${passcode}${getAppliedMeetingBrandingTheme
                    ? `&getAppliedMeetingBrandingTheme=${getAppliedMeetingBrandingTheme}`
                    : ""}`;
                break;
            case JoinInfo_1.JoinType.Meetup:
                const { tenantId, organizerId, threadId, messageId } = requestParamInfo;
                this.brandingEventProps.tenantId = tenantId;
                this.brandingEventProps.organizerId = organizerId;
                this.brandingServiceUrl = `${absoluteUrls.brandingService}?tenantId=${tenantId}&userId=${organizerId}${!!threadId ? `&threadId=${threadId}` : ""}${!!messageId ? `&messageId=${messageId}` : ""}`;
                break;
            default:
                const scrubbedInfo = Utils_1.Utils.scrubEuii(Utils_1.Utils.scrubPasscode(JSON.stringify(requestParamInfo)));
                throw new Error(`Invalid branding request parameters kind. Request parameters: ${scrubbedInfo}`);
        }
    }
    getBrandingTheme(jsonResponse) {
        if (jsonResponse &&
            jsonResponse.policy &&
            (jsonResponse.policy.defaultTheme || jsonResponse.policy.appliedMeetingBrandingTheme) &&
            jsonResponse.policy.meetingBrandingThemes) {
            let theme;
            // Sets the theme to the applied theme if it exists
            if (jsonResponse.policy.appliedMeetingBrandingTheme) {
                theme = jsonResponse.policy.meetingBrandingThemes.find(({ identity }) => identity === jsonResponse.policy.appliedMeetingBrandingTheme);
            }
            // If the applied theme is not found or not enabled, set the theme to the default theme
            if (!theme || !theme.enabled) {
                theme = jsonResponse.policy.meetingBrandingThemes.find(({ identity }) => identity === jsonResponse.policy.defaultTheme);
            }
            if (theme && theme.enabled && theme.brandAccentColor) {
                return {
                    backgroundImagePreAuthUri: this.brandingWithImages
                        ? theme.backgroundImageLightPreAuthUri
                            ? this.changeImageSizePropToIMGO(theme.backgroundImageLightPreAuthUri)
                            : theme.backgroundImageDarkPreAuthUri
                                ? this.changeImageSizePropToIMGO(theme.backgroundImageDarkPreAuthUri)
                                : ""
                        : "",
                    logoImagePreAuthUri: this.brandingWithImages
                        ? theme.logoImageLightPreAuthUri
                            ? this.changeImageSizePropToIMGO(theme.logoImageLightPreAuthUri)
                            : theme.logoImageDarkPreAuthUri
                                ? this.changeImageSizePropToIMGO(theme.logoImageDarkPreAuthUri)
                                : ""
                        : "",
                    brandAccentColor: theme.brandAccentColor,
                    displayName: theme.displayName,
                    enabled: theme.enabled,
                    identity: theme.identity,
                };
            }
        }
        return undefined;
    }
    getBrandingThemeContent(brandingTheme) {
        const brandingThemeContent = {};
        if (brandingTheme.brandAccentColor) {
            brandingThemeContent.hasColor = true;
        }
        if (brandingTheme.backgroundImagePreAuthUri) {
            brandingThemeContent.hasBackground = true;
        }
        if (brandingTheme.logoImagePreAuthUri) {
            brandingThemeContent.hasLogo = true;
        }
        return brandingThemeContent;
    }
    updateBrandingSteps(step) {
        this.brandingSteps = Object.assign(Object.assign({}, this.brandingSteps), { [step]: Date.now() - this.brandingDelta });
        this.brandingStepsWithTimestamp = Object.assign(Object.assign({}, this.brandingStepsWithTimestamp), { [step]: new Date().toISOString() });
        this.brandingDelta = Date.now();
    }
    applyBrandingTheme(brandAccentColor, imageURIs = {}) {
        let brandingClass = "brandingNoImages";
        // Logo and background both available
        const logoImagePreAuthUri = imageURIs["logoImagePreAuthUri"];
        const backgroundImagePreAuthUri = imageURIs["backgroundImagePreAuthUri"];
        if (logoImagePreAuthUri && backgroundImagePreAuthUri) {
            brandingClass = "brandingAllImages";
            Utils_1.Utils.applyImageToContainer(logoImagePreAuthUri, "logoContainer");
            Utils_1.Utils.applyImageToContainer(backgroundImagePreAuthUri, "headerContainer");
        }
        // Only background, no logo
        else if (!logoImagePreAuthUri && backgroundImagePreAuthUri) {
            brandingClass = "brandingBackgroundOnly";
            Utils_1.Utils.applyImageToContainer(backgroundImagePreAuthUri, "headerContainer");
        }
        // Only logo, no branding
        else if (!backgroundImagePreAuthUri && logoImagePreAuthUri) {
            brandingClass = "brandingLogoOnly";
            Utils_1.Utils.applyImageToContainer(logoImagePreAuthUri, "logoContainer");
        }
        Utils_1.Utils.addClassToContainer(brandingClass, "modernViewContainer");
        // Apply branding colors
        this.setBrandingColors(brandAccentColor);
        Utils_1.Utils.addClassToContainer("brandingColors", "modernViewContainer");
    }
    setBrandingColors(color) {
        const brandingColors = this.getBrandedColors(this.fastPlaceholder, color);
        // Update branding CSS variables with computed colors
        this.document.documentElement.style.setProperty("--branding-primary-button-color", brandingColors.background);
        this.document.documentElement.style.setProperty("--branding-primary-button-color-hover", brandingColors.backgroundHover);
        this.document.documentElement.style.setProperty("--branding-primary-button-color-active", brandingColors.backgroundPressed);
        this.document.documentElement.style.setProperty("--branding-anchor-color", brandingColors.background);
    }
    applyUnbrandedTheme() {
        this.updateBrandingSteps("ApplyUnbrandedTheme");
        try {
            Utils_1.Utils.addClassToContainer("noBranding", "modernViewContainer");
        }
        catch (e) {
            this.brandingEventProps.error = "applyUnbrandedThemeError";
            this.brandingEventProps.message = Utils_1.Utils.scrubEuii(e);
        }
    }
}
exports.BrandingService = BrandingService;
