"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultFeatureFlags = exports.featureFlagAliases = void 0;
// Due to technical debt, we have to maintain alternative feature flag names for some cases
exports.featureFlagAliases = {
    enableDesktopContextualInstaller: "contextualInstall",
    enableiOSJoinLauncherPage: "enableiOSJoinLancherPage", // typo is intentional, unfortunately
};
exports.defaultFeatureFlags = {
    enableDesktopContextualInstaller: false,
    enableiOSJoinLauncherPage: false,
    showiOSSmartBannerOnFirstLaunch: false,
    enableFQDN: false,
    enableExperiment: false,
    enableT21WebRedirect: false,
    enableMcasWebRedirect: false,
    enableT21WebRedirectSafari: false,
    enableT21WebRedirectFirefox: false,
    enableT21WebRedirectForTLE: true,
    enableT21McasForTenant: [],
    hideDownloadButton: false,
    reportUserRepeatTelemetry: false,
    suppressPrompt: false,
    suppressAutoDownload: false,
    promptSuccess: false,
    msLaunch: false,
    directDl: false,
    enableWebJoin: true,
    badOSRedirectToWebJoin: true,
    enableAria: true,
    enableMobilePage: true,
    enableAddingTenantIdInQsp: false,
    enableTflRequestContextForDesktop: false,
    enableTflRequestContextForMobile: false,
    enableTflCommunityEvents: false,
    enableTflCommunityEventsAddToCalendar: false,
    enableTflCommunityEventsViewDetails: false,
    enableAutoDownload: false,
    enableWindowsTfwUI: true,
    enableVisitorMarking: false,
    enableWebJoinForNewVisitors: false,
    enableBroadcastAttendeeConveneJoin: false,
    enableBranding: true,
    minSupportedFirefoxVersion: 96,
    minT21SupportedChromiumVersion: 80,
    minT21SupportedFirefoxVersion: 80,
    minT21SupportedSafariVersion: 16,
    enableTflDesktopGoogleSync: false,
    ignoreBrandingTimeout: false,
    brandingFetchTimeout: 2000,
    brandingWithImages: false,
    lightMeetingsBuildVersion: "24012223400",
    lightMeetingsWebBuildVersion: "24012223400",
    enablePDSRedirect: false,
    enableLimeRedirectFromWebjoin: false,
    enableLimeRedirectFromUnsupportedOS: false,
    enableLimeRedirectForMeetingId: false,
    enableLimeRedirectForMeetingIdRoomOS: false,
    enableAnonymousReporting: false,
    enableSchoolConnection: false,
    enableOrganizationPrivacyStatement: false,
    fetchOrganizationPrivacyStatementTimeout: 2000,
    enableBrandingFetchWithMeetingId: false,
    shouldGetAppliedMeetingBrandingTheme: false,
};
