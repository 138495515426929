"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BootstrapService = void 0;
const UrlHelper_1 = require("../helpers/UrlHelper");
const Utils_1 = require("../helpers/Utils");
const Environment_1 = require("../models/Environment");
const JoinInfo_1 = require("../models/JoinInfo");
const TelemetryEvents_1 = require("../models/telemetry/TelemetryEvents");
const Logger_1 = require("./Logger");
const TelemetryScenarioTracker_1 = require("./TelemetryScenarioTracker");
const LauncherService_1 = require("./LauncherService");
const ConfigurationService_1 = require("./ConfigurationService");
const ServerParamsService_1 = require("./ServerParamsService");
const numbers = /^[0-9]+$/;
const FORCE_WEBJOIN_EXP_ID = "JoinLauncherExperiment";
class BootstrapService {
    constructor(environment, featureFlags) {
        this.environment = environment;
        this.featureFlags = featureFlags;
        this.getJoinInfo = () => ({
            isValid: !!this.conversationUrl && !!this.type,
            deepLinkIds: this.deepLinkIds,
            correlationId: this.correlationId,
            conversationUrl: this.conversationUrl,
            deepLinkUrl: this.deepLinkUrl,
            type: this.type,
            isPrivate: this.isPrivate,
            threadId: this.threadId,
            messageId: this.messageId,
            meetingInfo: this.meetingInfo,
            shareEmailInfo: this.shareEmailInfo,
            guestAccessTenantId: this.guestAccessTenantId,
            ring: this.ring,
            isAnonymousEnabled: this.isAnonymousEnabled,
            forceWebJoin: this.forceWebJoin,
            code: this.code,
            passcode: this.passcode,
            launchAgent: this.launchAgent,
            laEntry: this.launchAgentEntry,
            laExpId: this.launchAgentExperimentId,
            isMeetingCreation: this.isMeetingCreation,
            isTflLink: this.isTflLink,
            deepLinkSource: this.deepLinkSource,
            eventTypeQsp: this.eventTypeQsp,
            redirectToLightMeetingsExperienceForDGJ: this.redirectToLightMeetingsExperienceForDGJ,
            forceLightMeetingsExperienceOnWeb: this.forceLightMeetingsExperienceOnWeb,
            redirectFromPDS: this.redirectFromPDS,
            isAnonymous: this.isAnonymous,
            dgjPartnerId: this.dgjPartnerId,
        });
        this.getUserInfo = () => {
            const ring = this.environment.ring;
            const isPossiblyBot = this.isPossiblyBot();
            const userInfo = { ring, isPossiblyBot };
            if (this.environment.buildEnvironment === Environment_1.BuildEnvironment.Life) {
                userInfo.tenantModel = 3;
            }
            return userInfo;
        };
        this.getDeepLinkIds = (queryParams, entityType) => {
            const clientId = Utils_1.Utils.generateGuid();
            const isCallingDeeplink = entityType === JoinInfo_1.JoinType.Meetup ||
                entityType === JoinInfo_1.JoinType.Call ||
                entityType === JoinInfo_1.JoinType.MeetupWithCode;
            return {
                server: Utils_1.Utils.scrubEuii(this.getQueryParam("deeplinkId", queryParams)),
                client: clientId,
                web: isCallingDeeplink ? Utils_1.Utils.generateGuid() : clientId,
            };
        };
        this.getQueryParam = (key, queryParams) => {
            const value = queryParams[key];
            return Array.isArray(value) ? value[0] : value;
        };
        this.getContextInfo = () => {
            if (this.type !== JoinInfo_1.JoinType.Meetup) {
                return;
            }
            const threadContext = this.getQueryParam("context", this.conversationQueryParams);
            try {
                return JSON.parse(threadContext);
            }
            catch (parseError) {
                try {
                    let sanitizedThreadContext = threadContext.replace(/(?:['"])?([\w-]+)(?:['"])?\s*:\s*(?:['"])?([\w-]+)(?:['"])?/g, (_, key, value) => `"${key}":"${value}"`);
                    const emailIncluded = sanitizedThreadContext.indexOf("@") > -1;
                    const telIncluded = sanitizedThreadContext.indexOf("tel:") > -1;
                    // trying to strip characters out of JSON literal object definition
                    const start = sanitizedThreadContext.indexOf("{");
                    const end = sanitizedThreadContext.indexOf("}");
                    sanitizedThreadContext = sanitizedThreadContext.substring(start > -1 ? start : 0, end > -1 ? end + 1 : undefined);
                    const context = JSON.parse(sanitizedThreadContext);
                    Logger_1.default.log(new TelemetryEvents_1.InitialParsingFailedEvent({
                        newContext: sanitizedThreadContext,
                        contextContainedEmail: emailIncluded,
                        contextContainedTel: telIncluded,
                    }));
                    // replace the malformed URL
                    this.conversationUrl = this.conversationUrl.replace(encodeURIComponent(threadContext), encodeURIComponent(sanitizedThreadContext));
                    return context;
                }
                catch (sanitizedParseError) {
                    Logger_1.default.log(new TelemetryEvents_1.ParsingFailedEvent());
                    return;
                }
            }
        };
        this.getShareEmailInfo = () => {
            if (this.type !== JoinInfo_1.JoinType.ShareEmail) {
                return;
            }
            // Get email id from QSP or from the last part of URL path
            const emailId = this.getQueryParam("emailId", this.conversationQueryParams) ||
                UrlHelper_1.UrlHelper.pathParts(this.conversationUrl).slice(-1)[0];
            const attachmentCount = parseInt(this.getQueryParam("attachmentCount", this.conversationQueryParams), 10) || 0;
            const attachmentSize = parseInt(this.getQueryParam("attachmentSize", this.conversationQueryParams), 10) || 0;
            const fileTypes = this.getQueryParam("fileTypes", this.conversationQueryParams);
            const tid = this.getQueryParam("tid", this.conversationQueryParams);
            const uid = this.getQueryParam("uid", this.conversationQueryParams);
            let webUrl = `${UrlHelper_1.UrlHelper.getOrigin()}/share/email/create?emailRestId=${emailId}`;
            if (attachmentCount) {
                webUrl += `&attachmentCount=${attachmentCount}`;
            }
            if (attachmentSize) {
                webUrl += `&attachmentSize=${attachmentSize}`;
            }
            if (fileTypes) {
                webUrl += `&fileTypes=${fileTypes}`;
            }
            if (tid) {
                webUrl += `&outlookTid=${tid}`;
            }
            if (uid) {
                webUrl += `&outlookUid=${uid}`;
            }
            return {
                webUrl,
            };
        };
        this.getMeetingInfo = () => {
            if (!this.context) {
                return;
            }
            const data = this.context;
            if (!data || !data.Tid || !data.Oid) {
                return;
            }
            return {
                tenantId: Utils_1.Utils.scrubEuii(data.Tid),
                organizerId: Utils_1.Utils.scrubEuii(data.Oid),
                isBroadcast: data.IsBroadcastMeeting === true || data.b === 1 || data.b === "1",
                isComingFromWebClient: data.Web === "true",
            };
        };
        this.getThreadId = (splitDeepLinkUrl) => {
            // TFL Contact sync contains users msa email on 3rd position and must not be exposed as thread Id as it can leak to telemetry
            if (this.type === JoinInfo_1.JoinType.TflContactSync) {
                return;
            }
            if (splitDeepLinkUrl.length >= 4) {
                return Utils_1.Utils.scrubEuii(splitDeepLinkUrl[3]);
            }
            return;
        };
        this.getCode = (splitDeepLinkUrl) => {
            if (splitDeepLinkUrl.length > 2 && splitDeepLinkUrl[2].match(numbers)) {
                return Utils_1.Utils.scrubEuii(splitDeepLinkUrl[2]);
            }
            return;
        };
        this.getMessageId = (splitDeepLinkUrl) => {
            if (splitDeepLinkUrl.length > 4 && splitDeepLinkUrl[4].match(numbers)) {
                return Utils_1.Utils.scrubEuii(splitDeepLinkUrl[4]);
            }
            return;
        };
        this.getDeepLinkSource = () => {
            // QSP "s" denotes source of deep link from which user got redirected to launcher ex-sms
            const deeplinkSource = this.getQueryParam("s", this.conversationQueryParams);
            return Utils_1.Utils.scrubEuii(deeplinkSource);
        };
        this.getIsPrivate = (messageId) => messageId === "0";
        this.recordJoinAttempt = () => {
            const joinAttemptStorageKey = "lastMeetingContext";
            const maxTimeForRepeatedMeetingLaunch = 3600000;
            const currentLaunchTime = new Date().getTime();
            let retryCount = 0;
            const previousDeeplinkIdList = [];
            let lastJoinAttempts = {};
            const meetingId = this.type === JoinInfo_1.JoinType.Meetup ? this.threadId : this.code;
            try {
                const storageItem = localStorage.getItem(joinAttemptStorageKey);
                if (storageItem) {
                    lastJoinAttempts = JSON.parse(storageItem);
                    if (meetingId in lastJoinAttempts) {
                        const lastJoinAttempt = lastJoinAttempts[meetingId];
                        const lastLaunchTime = lastJoinAttempt.timestamp;
                        if (currentLaunchTime - lastLaunchTime < maxTimeForRepeatedMeetingLaunch) {
                            retryCount = lastJoinAttempt.retryCount + 1;
                            previousDeeplinkIdList.push(...lastJoinAttempt.previousDeeplinkIdList);
                        }
                    }
                }
                previousDeeplinkIdList.push(this.deepLinkIds.client);
                const joinAttempt = {
                    meetingId,
                    retryCount,
                    timestamp: currentLaunchTime,
                    previousDeeplinkIdList,
                };
                lastJoinAttempts[meetingId] = joinAttempt;
                localStorage.setItem(joinAttemptStorageKey, JSON.stringify(lastJoinAttempts));
                return joinAttempt;
            }
            catch (error) {
                // Navigator can have localStorage disabled (or private mode)
                return {
                    meetingId,
                    retryCount: 0,
                    timestamp: currentLaunchTime,
                    previousDeeplinkIdList: new Array(this.deepLinkIds.client),
                };
            }
        };
        const startScenario = TelemetryScenarioTracker_1.TelemetryScenarioTracker.getOrCreateScenario(TelemetryScenarioTracker_1.ScenarioNames.JoinLauncherStart);
        this.queryParams = UrlHelper_1.UrlHelper.getQueryParameters();
        this.conversationUrl = this.getQueryParam("url", this.queryParams);
        let typeValue = this.getQueryParam("type", this.queryParams);
        this.deepLinkIds = this.getDeepLinkIds(this.queryParams, typeValue);
        this.userInfo = this.getUserInfo();
        Logger_1.default.log(new TelemetryEvents_1.LoadingLauncherStartEvent());
        this.redirectFromPDS = this.isRedirectFromPDS(this.conversationUrl, typeValue);
        if (this.redirectFromPDS) {
            // Requests redirected from PDS have the conversationUrl in the hash instead of query
            const supportedJoinTypes = this.isLimeSupportedJoinType(JoinInfo_1.JoinType.MeetupWithCode)
                ? [JoinInfo_1.JoinType.Meetup, JoinInfo_1.JoinType.MeetupWithCode]
                : [JoinInfo_1.JoinType.Meetup];
            const { url, type } = UrlHelper_1.UrlHelper.getHashParameters(supportedJoinTypes);
            if (!url || !type) {
                Logger_1.default.setContext(this.deepLinkIds, typeValue, this.userInfo);
                Logger_1.default.log(new TelemetryEvents_1.PDSRedirectEvent());
                UrlHelper_1.UrlHelper.redirect(this.getPDSRedirectURL());
                return;
            }
            this.conversationUrl = url;
            typeValue = type;
            this.deepLinkIds.web = Utils_1.Utils.generateGuid();
        }
        if (!this.conversationUrl || !typeValue) {
            Logger_1.default.setContext(this.deepLinkIds, typeValue, this.userInfo);
            Logger_1.default.log(new TelemetryEvents_1.LoadingLauncherErrorEvent());
            startScenario.fail("Missing required parameters");
            UrlHelper_1.UrlHelper.redirect(ConfigurationService_1.configurationService.getConfig().urls.paths.error());
            return;
        }
        this.type = typeValue;
        this.ring = this.getQueryParam("ring", this.queryParams);
        this.conversationQueryParams = UrlHelper_1.UrlHelper.getQueryParameters(this.conversationUrl);
        this.context = this.getContextInfo();
        this.meetingInfo = this.getMeetingInfo();
        this.shareEmailInfo = this.getShareEmailInfo();
        this.extendLoggerContext();
        this.isAnonymousEnabled = this.getQueryParam("anon", this.conversationQueryParams) === "true";
        this.deepLinkSource = this.getDeepLinkSource();
        this.guestAccessTenantId = this.getQueryParam("tenantId", this.conversationQueryParams);
        this.deepLinkUrl = this.conversationUrl.replace(LauncherService_1.AUTHENTICATED_WEB_REDIRECT_ROUTE, "");
        const deepLinkUrlParts = UrlHelper_1.UrlHelper.stripParams(this.deepLinkUrl).split("/");
        this.messageId = this.getMessageId(deepLinkUrlParts);
        this.isPrivate = this.getIsPrivate(this.messageId);
        this.threadId = this.getThreadId(deepLinkUrlParts);
        if (this.type === JoinInfo_1.JoinType.MeetupWithCode) {
            this.code = this.getCode(deepLinkUrlParts);
            // Set meeting id to context for reporting, for meetup with code, code is considered being the meeting Id
            Logger_1.default.setMeetingIdContext(this.code);
            this.passcode = this.getQueryParam("p", this.conversationQueryParams);
            // Available for TFL community events only
            if (this.environment.isTflEnvironment) {
                this.eventTypeQsp = this.getQueryParam("eventType", this.conversationQueryParams);
            }
        }
        // Eventually all links on TFL environment should be considered TFL Links but this would need more code cleanup and testing
        this.isTflLink =
            this.type === JoinInfo_1.JoinType.TflInvite ||
                this.type === JoinInfo_1.JoinType.TflClaim ||
                this.type === JoinInfo_1.JoinType.TflFunnel ||
                this.type === JoinInfo_1.JoinType.TflContactSync ||
                this.type === JoinInfo_1.JoinType.TflCommunity ||
                (this.type === JoinInfo_1.JoinType.SchoolConnection && featureFlags.enableSchoolConnection) ||
                this.type === JoinInfo_1.JoinType.TeamsInsider ||
                (this.environment.isTflEnvironment && // Moving this check here as Cypress tests don't have good environment support yet
                    (this.type === JoinInfo_1.JoinType.Message ||
                        this.type === JoinInfo_1.JoinType.Meetup ||
                        this.type === JoinInfo_1.JoinType.MeetupWithCode));
        const isWebjoinEnabled = this.getQueryParam("webjoin", this.conversationQueryParams) === "true";
        const isForceWebjoinEnabledForTFW = !this.isTflLink && this.isJoinMeetingType() && this.featureFlags.enableWebJoinForNewVisitors;
        this.forceWebJoin = isWebjoinEnabled || isForceWebjoinEnabledForTFW || this.redirectFromPDS;
        this.launchAgent = this.getQueryParam("launchAgent", this.conversationQueryParams);
        this.launchAgentEntry = this.getQueryParam("laEntry", this.conversationQueryParams);
        this.launchAgentExperimentId = this.getLaunchExpId(isForceWebjoinEnabledForTFW);
        this.isMeetingCreation = !!this.getQueryParam("isMeetingCreation", this.conversationQueryParams);
        this.correlationId = this.getQueryParam("correlationId", this.conversationQueryParams);
        if (this.featureFlags.enableAnonymousReporting) {
            // PDS should be the source of truth since they check for
            // token validity, but we will still report to telemetry what the JL
            // sees to check for discrepancies and decide what's the best option.
            const jlLocalAnonymous = !this.hasAuthToken();
            const pdsAnonymous = !!ServerParamsService_1.default.getServerParams().anonymous;
            Logger_1.default.setAnonymousContext(pdsAnonymous, jlLocalAnonymous);
            this.isAnonymous = pdsAnonymous;
        }
        this.dgjPartnerId = this.getQueryParam("dgjpartnerid", this.conversationQueryParams);
        Logger_1.default.setDGJPartnerIdContext(this.dgjPartnerId);
        this.redirectToLightMeetingsExperienceForDGJ = this.isLightMeetingsEnabledForDGJ();
        this.forceLightMeetingsExperienceOnWeb =
            this.getQueryParam("lightExperience", this.conversationQueryParams) === "true";
        Logger_1.default.log(new TelemetryEvents_1.ParsingCompleteEvent({
            isPrivate: this.isPrivate,
            threadId: this.threadId,
            type: this.type,
            code: this.code,
        }));
        this._logLoadingComplete();
        startScenario.markStep(TelemetryScenarioTracker_1.ScenarioStepNames.Bootstrap, TelemetryScenarioTracker_1.ScenarioStatuses.Success, {
            correlationId: this.correlationId,
            launchAgent: this.launchAgent,
            laEntry: this.launchAgentEntry,
            laExpId: this.launchAgentExperimentId,
            isMeetingCreation: this.isMeetingCreation,
        });
        window.addEventListener("load", () => {
            this._reportScriptErrorIfAny();
        });
    }
    _reportScriptErrorIfAny() {
        var _a;
        for (const scriptErrorLog of scriptErrorLogs) {
            if (scriptErrorLog) {
                const errorEvent = new TelemetryEvents_1.ScriptErrorEvent({
                    message: Utils_1.Utils.scrubEuii(scriptErrorLog.message),
                    error: JSON.stringify({
                        source: Utils_1.Utils.stripQSPFromUrl(scriptErrorLog.source),
                        line: scriptErrorLog.line,
                        col: scriptErrorLog.col,
                        error: Utils_1.Utils.scrubEuii((_a = scriptErrorLog.error) === null || _a === void 0 ? void 0 : _a.stack),
                    }),
                });
                Logger_1.default.log(errorEvent);
            }
        }
    }
    getLaunchExpId(isForceWebjoinEnabledForTFW) {
        // ?v=value for TFL links, laExpId for outlook, special case for forcedWebJoin experiment
        if (isForceWebjoinEnabledForTFW) {
            return FORCE_WEBJOIN_EXP_ID;
        }
        else if (this.isTflLink) {
            return this.getQueryParam("v", this.conversationQueryParams);
        }
        else {
            return this.getQueryParam("laExpId", this.conversationQueryParams);
        }
    }
    isJoinMeetingType() {
        return this.type === JoinInfo_1.JoinType.Meetup || this.type === JoinInfo_1.JoinType.MeetupWithCode;
    }
    isPossiblyBot() {
        try {
            const de = window.document.documentElement;
            const documentKeys = Object.keys(window.document);
            return (!!Object.keys(window).find(key => [
                "_phantom",
                "callPhantom",
                "__nightmare",
                "_selenium",
                "callSelenium",
                "_Selenium_IDE_Recorder",
                "Cypress",
            ].indexOf(key) > -1) || // check of explicit values on window
                !!documentKeys.find(key => key.indexOf("__") === 0 && (key.indexOf("driver") > -1 || key.indexOf("selenium") > -1)) || // check if property with driver or selenium in name on document object
                !!documentKeys.find(key => key.match(/\$[a-z]dc_/) &&
                    Object.prototype.hasOwnProperty.call(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    window.document[key], "cache_")) ||
                !!de.getAttribute("selenium") ||
                !!de.getAttribute("driver") ||
                !!de.getAttribute("webdriver"));
        }
        catch (e) {
            return false;
        }
    }
    hasAuthToken() {
        try {
            const token = window.localStorage.getItem("msal.idtoken") ||
                window.localStorage.getItem("adal.idtoken") ||
                window.localStorage.getItem("msal.activeUserProfile");
            return !!token;
        }
        catch (_a) {
            return false;
        }
    }
    extendLoggerContext() {
        const launchSource = this.getQueryParam("lmsrc", this.conversationQueryParams);
        const context = this.type === JoinInfo_1.JoinType.Meetup && this.context ? JSON.stringify(this.context) : undefined;
        Logger_1.default.setContext(this.deepLinkIds, this.type, this.userInfo, launchSource, context);
    }
    _logLoadingComplete() {
        let retryCount;
        let previousDeeplinkIds;
        let isMultiTab;
        if (this.featureFlags.reportUserRepeatTelemetry &&
            (this.type === JoinInfo_1.JoinType.Meetup || this.type === JoinInfo_1.JoinType.MeetupWithCode)) {
            const joinAttempt = this.recordJoinAttempt();
            retryCount = joinAttempt.retryCount;
            previousDeeplinkIds = joinAttempt.previousDeeplinkIdList.join(",");
            isMultiTab = retryCount > 0;
        }
        Logger_1.default.log(new TelemetryEvents_1.LoadingLauncherCompleteEvent({
            retryCount,
            previousDeeplinkIdList: previousDeeplinkIds,
            organizerId: Utils_1.Utils.scrubInvalidGuid(this.meetingInfo && this.meetingInfo.organizerId),
            tenantId: this.meetingInfo && this.meetingInfo.tenantId,
            threadId: this.threadId,
            code: this.code,
            isMultiTab,
        }));
    }
    isLimeSupportedJoinType(joinType) {
        return (joinType === JoinInfo_1.JoinType.Meetup ||
            (joinType === JoinInfo_1.JoinType.MeetupWithCode &&
                !!this.featureFlags.enableLimeRedirectForMeetingId) ||
            (joinType === JoinInfo_1.JoinType.MeetupWithCode &&
                !!this.featureFlags.enableLimeRedirectForMeetingIdRoomOS &&
                !!this.environment.isRoomOSEnvironment));
    }
    // DGJ stands for Direct Guest Join. More information:
    // https://learn.microsoft.com/en-us/microsoftteams/rooms/third-party-join
    isLightMeetingsEnabledForDGJ() {
        const isValidLightMeetingsGUID = this.getQueryParam(ConfigurationService_1.configurationService.getConfig().urls.lightMeetings.thirdPartyDeviceGUID, this.conversationQueryParams) === "true";
        const isLimeRedirectFromWebjoin = this.forceWebJoin && !!this.featureFlags.enableLimeRedirectFromWebjoin;
        const isLimeRedirectFromUnsupportedOS = this.environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Linux &&
            this.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            this.environment.isWebClientSupportedBrowser &&
            !this.environment.isTflEnvironment &&
            !!this.featureFlags.enableLimeRedirectFromUnsupportedOS;
        const isLimeSupported = this.isLimeSupportedJoinType(this.type) &&
            (isValidLightMeetingsGUID ||
                this.redirectFromPDS ||
                isLimeRedirectFromWebjoin ||
                isLimeRedirectFromUnsupportedOS);
        const meetingHostOnWindowObject = !!window.meetingHost;
        return isLimeSupported && meetingHostOnWindowObject;
    }
    isRedirectFromPDS(conversationUrl, type) {
        return (!conversationUrl &&
            !type &&
            !!this.featureFlags.enablePDSRedirect &&
            !!this.environment.isDgjEnvironment);
    }
    getPDSRedirectURL() {
        return UrlHelper_1.UrlHelper.addPDSQueryParameter(`${UrlHelper_1.UrlHelper.getOrigin()}/_${UrlHelper_1.UrlHelper.getHash()}`, "preventJLRedirect", "true");
    }
}
exports.BootstrapService = BootstrapService;
